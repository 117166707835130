import { User } from '../models/user.model';

export const users: User[] = [
    {
        id: 'af2c8043-1724-11ee-b32b-fa163e5cdf3e',
        email: 'metalcouser@metalco.com',
        password: 'Skeinholding_2023!', // 'metalco@123',
        company: 'metalco',
        companyLogo: '../assets/images/logo_ok.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL01ldGFsY29fMTNfMDRfMjEucnZ0'
        //RD
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fMTNfMDRfMjEucnZ0'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfdGFiZWxsYTEucnZ0'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfdGFiZWxsYTJfdXNlcjEucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfcGFuY2hpbmVfY29tcGxldG8ucnZ0'
    },
    {
        id: 'b8c64500-1724-11ee-b32b-fa163e5cdf3e',
        email: 'metalcouser01@metalco.com',
        password: 'Skeinholding_2023!', // 'metalco@123',
        company: 'metalco',
        companyLogo: '../assets/images/logo_ok.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfcGFuY2hpbmUlMjAxLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfaW4lMjBzdmlsdXBwby5ydnQ'
    },
    {
        id: 'bfa1b862-1724-11ee-b32b-fa163e5cdf3e',
        email: 'metalcouser02@metalco.com',
        password: 'Skeinholding_2023!', // 'metalco@123',
        company: 'metalco',
        companyLogo: '../assets/images/logo_ok.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfcGFuY2hpbmUlMjAyLnJ2dA'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfcGFuY2hpbmUlMjAybmV3LnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfcGFuY2hpbmVfMk5ldy5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfdGFiZWxsYTIucnZ0'
    },
    {
        id: 'c6c0e400-1724-11ee-b32b-fa163e5cdf3e',
        email: 'check@metalco.com',
        password: 'Skeinholding_2023!', // 'metalco@123',
        company: 'metalco',
        companyLogo: '../assets/images/logo_ok.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01ldGFsY29fdGVtcGxhdGVfdGFiZWxsYTMucnZ0'
    },
    {
        id: 'cf3f5bf2-1724-11ee-b32b-fa163e5cdf3e',
        email: 'metalco2@metalco.it',
        password: 'Skeinholding_2023!', // 'metalco123@',
        company: 'metalco',
        companyLogo: '../assets/images/logo_ok.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwcGFjayUyMDExJTIwMi5ydnQ'
    },
    {
        id: 'd74f616e-1724-11ee-b32b-fa163e5cdf3e',
        email: 'metalco3@metalco.it',
        password: 'Skeinholding_2023!', // 'metalco123@',
        company: 'metalco',
        companyLogo: '../assets/images/logo_ok.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwcGFjayUyMDIyJTIwMi5ydnQ'
    },
    {
        id: 'deba5213-1724-11ee-b32b-fa163e5cdf3e',
        email: 'metalco4@metalco.it',
        password: 'Skeinholding_2023!', // 'metalco123@',
        company: 'metalco',
        companyLogo: '../assets/images/logo_ok.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwcGFjayUyMDMzJTIwMi5ydnQ'
    },
    {
        id: 'e6797e77-1724-11ee-b32b-fa163e5cdf3e',
        email: 'metalcopack3@metalco.it',
        password: 'Skeinholding_2023!', // 'metalco123@',
        company: 'metalco',
        companyLogo: '../assets/images/logo_ok.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0RFTElWRVJZJTIwUEFDSyUyMDMlMjBsYXRlc3QucnZ0'
    },
    {
        id: 'ed7cb608-1724-11ee-b32b-fa163e5cdf3e',
        email: 'metalcopack4@metalco.it',
        password: 'Skeinholding_2023!', // 'metalco123@',
        company: 'metalco',
        companyLogo: '../assets/images/logo_ok.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0RFTElWRVJZJTIwUEFDSyUyMDQuLnJ2dA'
    },
    {
        id: 'f47632a8-1724-11ee-b32b-fa163e5cdf3e',
        email: 'metalcopack5@metalco.it',
        password: 'Skeinholding_2023!', // 'metalco123@',
        company: 'metalco',
        companyLogo: '../assets/images/logo_ok.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0RFTElWRVJZJTIwUEFDSyUyMDUucnZ0'
    },
    {
        id: 'fa64ab45-1724-11ee-b32b-fa163e5cdf3e',
        email: 'bellitalia@bellitalia.net',
        password: 'Skeinholding_2023!', // 'bellitalia@123',
        company: 'bellitalia',
        companyLogo: '../assets/images/logo_ok.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL2JlbGxpdGFsaWEucnZ0'
    },
    {
        id: '00d05890-1725-11ee-b32b-fa163e5cdf3e',
        email: 'overplanuser@overplan.com',
        password: 'Skeinholding_2023!', // 'overplan@123',
        company: 'overplan',
        companyLogo: '../assets/images/logooverplanen.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL0FMRkEucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL0FMRkEucnZ0'
        //RD
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0FMRkEucnZ0'
    },
    {
        id: '08361777-1725-11ee-b32b-fa163e5cdf3e',
        email: 'overplan@overplan.com',
        password: 'Skeinholding_2023!', // 'overplan@123',
        company: 'overplan',
        companyLogo: '../assets/images/logooverplanen.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL0JTX1ZpYV8lMjBHZXppbyUyMENhbGluaS5ydnQ'
        //RD
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0JTX1ZpYV8lMjBHZXppbyUyMENhbGluaS5ydnQ'
    },
    {
        id: '0e7da060-1725-11ee-b32b-fa163e5cdf3e',
        email: '21320@overplan.com',
        password: 'Skeinholding_2023!', // 'overplan@123',
        company: 'overplan',
        companyLogo: '../assets/images/logooverplanen.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL1NhbG8lMjB2aWElMjBmYW50b25pLnJ2dA'
        //RD
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1NhbG8lMjB2aWElMjBmYW50b25pLnJ2dA'
    },
    {
        id: '153af546-1725-11ee-b32b-fa163e5cdf3e',
        email: 'overplan1@overplan.com',
        password: 'Skeinholding_2023!', // 'overplan@123',
        company: 'overplan',
        companyLogo: '../assets/images/logooverplanen.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwLzE5NV8yMl9MQTAxX0xUQV9CSU1fTU9EX0FSQ18wMVIwMF9kaXNzb2NpYXRvLnJ2dA'
    },
    {
        id: '1be2f608-1725-11ee-b32b-fa163e5cdf3e',
        email: 'overplan2@overplan.com',
        password: 'Skeinholding_2023!', // 'overplan@123',
        company: 'overplan',
        companyLogo: '../assets/images/logooverplanen.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwLzIyMTJfQVJDX1BBRDAzX1YyMC1Ta2VpbkJJTS1fZGlzc29jaWF0by5ydnQ'
    },
    {
        id: '21f8f7d5-1725-11ee-b32b-fa163e5cdf3e',
        email: 'landauser@landa.com',
        password: 'Skeinholding_2023!', // 'landa@123',
        company: 'landa',
        companyLogo: '../assets/images/landa.jpeg',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL0xhbmRhJTIwVGVtcGxhdGUucnZ0'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL0xhbmRhX3RlbXBsYXRlX2FnZ2lvcm5hdG8ucnZ0'
        //RD
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0xhbmRhX3RlbXBsYXRlXzIwXzA3XzIwMjEucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0xhbmRhX3RlbXBsYXRlXzI2XzA3XzIwMjEucnZ0'
    },
    {
        id: '28d9b2cc-1725-11ee-b32b-fa163e5cdf3e',
        email: 'reggianiuser@reggiani.com',
        password: 'Skeinholding_2023!', // 'reggiani@123',
        company: 'reggiani',
        companyLogo: '../assets/images/reggiani.svg',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL1JlZ2dpYW5pJTIwWW9yaSUyMHRlbXBsYXRlLnJ2dA',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL1JlZ2dpYW5pJTIwVGVtcGxhdGUucnZ0'
        //RD
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1JlZ2dpYW5pJTIwVGVtcGxhdGUucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1JlZ2dpYW5pJTIwZmFtaWx5X1RlbXBsYXRlLnJ2dA'
    },
    {
        id: '2e984140-1725-11ee-b32b-fa163e5cdf3e',
        email: 'diasenuser@diasen.com',
        password: 'Skeinholding_2023!', // 'diasen@123',
        company: 'diasen',
        companyLogo: '../assets/images/diasen.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL0RpYXNlbiUyMHRlbXBsYXRlLnJ2dA'
        //RD
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0RpYXNlbiUyMHRlbXBsYXRlLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0RpYXNlbl90ZW1wbGF0ZS5ydnQ'
    },
    {
        id: '38c24772-1725-11ee-b32b-fa163e5cdf3e',
        email: 'unimetal@unimetal.com',
        password: 'Skeinholding_2023!', // 'unimetal@123',
        company: 'unimetal',
        companyLogo: '../assets/images/unimetal.jpeg',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL1VuaW1ldGFsJTIwdGVtcGxhdGUucnZ0'
        //RD
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1VuaW1ldGFsJTIwdGVtcGxhdGUucnZ0'
    },
    {
        id: 'daf68dcc-1716-11ee-b32b-fa163e5cdf3e',
        email: 'teknediluser@teknedil.com',
        password: 'Skeinholding_2023!', // 'teknedil@123',
        company: 'teknedil',
        companyLogo: '../assets/images/teknedil.jpeg',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6M2ViZTljNTItYzJlNC0xMWViLWFlMjAtMDIwMDAwNzUyODQwL1JlZ2dpYW5pJTIwVGVtcGxhdGUucnZ0'
        //RD
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RFS05FRElMJTIwdGVtcGxhdGUucnZ0'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Rla25lZGlsJTIwVGVtcGxhdGUtQWxsJTIwVHlwZXNfcmZhJTIwKDIpLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Rla25lZGlsJTIwVGVtcGxhdGUtQWxsJTIwVHlwZXMucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Rla25lZGlsJTIwVGVtcGxhdGUtQWxsJTIwVHlwZXNfMjMtMDUucnZ0'
    },
    {
        id: '3f1508fc-1725-11ee-b32b-fa163e5cdf3e',
        email: 'mitauser@mita.com',
        password: 'Skeinholding_2023!', // 'mita@123',
        company: 'mita',
        companyLogo: '../assets/images/mita.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBUZW1wbGF0ZS5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURSUyMFNlcmllcy5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURV8wOV8xMF8yMy5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURV8zMV8xMF8yMDIzLnJ2dA'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURV8wN18wMy5ydnQ',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURS5ydnQ'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURSUyMCgxKS5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURSUyMF9SRVYuMDEucnZ0'
    },
    {
        id: '4562f1ab-1725-11ee-b32b-fa163e5cdf3e',
        email: 'mitawateruser@mita.com',
        password: 'Skeinholding_2023!', // 'mitawater@123',
        company: 'mita-water',
        companyLogo: '../assets/images/mita-water.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURV8wNV9KdW5lLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURSUyMHdhdGVyLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEFfd2F0ZXJfVEVNUExBVEUxMF8xMF8yMDIzLnJ2dA'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURV8wOF8xMV8yMDIzLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEFfMTgwNDIwMjQucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JVEElMjBURU1QTEFURSUyMF9SRVYuMDEucnZ0'
    },
    {
        id: '4b3852d1-1725-11ee-b32b-fa163e5cdf3e',
        email: 'mivaluser@mival.com',
        password: 'Skeinholding_2023!', // 'mival@123',
        company: 'mival',
        companyLogo: '../assets/images/mival.jpg',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Byb2plY3QlMjBUZW1wbGF0ZS5ydnQ'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Byb2dldHRvMS5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Byb2dldHRvJTIwTUlWQUwucnZ0'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Byb2plY3QlMjBUZW1wbGF0ZS5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01pdmFsLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01pdmFsX25ldy5ydnQ'
    },
    {
        id: '50ac7907-1725-11ee-b32b-fa163e5cdf3e',
        email: 'biopietrauser@biopietra.com',
        password: 'Skeinholding_2023!', // 'biopietra@123',
        company: 'biopietra',
        companyLogo: '../assets/images/biopietra.svg',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Byb2plY3QlMjBUZW1wbGF0ZS5ydnQ'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Byb2dldHRvMS5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlX0Jpb3BpZXRyYV9CSU1fMjAyMi5ydnQ'
    },
    {
        id: '585252b1-1725-11ee-b32b-fa163e5cdf3e',
        email: 'beghelliuser@beghelli.com',
        password: 'Skeinholding_2023!', // 'beghelli@123',
        company: 'beghelli',
        companyLogo: '../assets/images/beghelli.svg',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0JlZ2hlbGxpJTIwVGVtcGxhdGUoMikucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0JlZ2hlbGxpJTIwVGVtcGxhdGUoMSlOZXcucnZ0'
    },
    {
        id: '5dbd92b9-1725-11ee-b32b-fa163e5cdf3e',
        email: 'coldlineuser@coldline.com',
        password: 'Skeinholding_2023!', // 'coldline@123',
        company: 'coldline',
        companyLogo: '../assets/images/coldline.jpeg',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0NPTERMSU5FX3RlbXBsYXRlLnJ2dA'
    },
    {
        id: '631ffaec-1725-11ee-b32b-fa163e5cdf3e',
        email: 'riwegauser@riwega.com',
        password: 'Skeinholding_2023!', // 'riwega@123',
        company: 'riwega',
        companyLogo: '../assets/images/riwega.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Jpd2VnYSUyMFRlbXBsYXRlLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Jpd2VnYSUyMFRlbXBsYXRlMi5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1Jpd2VnYSUyMFRlbXBsYXRlKDcpLnJ2dA'
    },
    {
        id: '6af46055-1725-11ee-b32b-fa163e5cdf3e',
        email: '197user@197.com',
        password: 'Skeinholding_2023!', // '197@123',
        company: 'unonovesette',
        companyLogo: '../assets/images/unonovesette.jpg',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwLzE5N19UZW1wbGF0ZS5ydnQ'
    },
    {
        id: '1fc13f2e-1726-11ee-b32b-fa163e5cdf3e',
        email: 'ebarauser@ebara.com',
        password: 'Skeinholding_2023!', // 'ebara@123',
        company: 'ebara',
        companyLogo: '../assets/images/ebara.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL2ViYXJhX1RlbXBsYXRlLnJ2dA'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlX2ViYXJhMi5ydnQ'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0ViYXJhXzAzVGVtcGxhdGUucnZ0'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0ViYXJhMDRfVGVtcGxhdGUucnZ0'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0ViYXJhJTIwVGVtcGxhdGUyMDIxLnJ2dA'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0VCQVJBJTIwVGVtcGxhdGVfMDJfMDhfMjAyMy5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0VCQVJBJTIwVGVtcGxhdGVfMjktMDgucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0VCQVJBJTIwVGVtcGxhdGVfMDhfMDlfMjAyMy5ydnQ'
    },
    {
        id: '74c68f6f-1725-11ee-b32b-fa163e5cdf3e',
        email: 'greenpipeuser@greenpipe.com',
        password: 'Skeinholding_2023!', // 'greenpipe@123',
        company: 'greenpipe',
        companyLogo: '../assets/images/greenpipe.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0dSRUVOJTIwUElQRSUyMFRlbXBsYXRlLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0dSRUVOJTIwUElQRSUyMFRlbXBsYXRlJTIwMS5ydnQ'
    },
    {
        id: '7b1f42db-1725-11ee-b32b-fa163e5cdf3e',
        email: 'careluser@carel.com',
        password: 'Skeinholding_2023!', // 'carel@123',
        company: 'carel',
        companyLogo: '../assets/images/carel.jpeg',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RFTVBMQVRFX0NBUkVMLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0NBUkVMJTIwVGVtcGxhdGUucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0NhcmVsX3RlbXBsYXRlXzAxLnJ2dA'
    },
    {
        id: '8156d0f5-1725-11ee-b32b-fa163e5cdf3e',
        email: 'careluser2@carel.com',
        password: 'Skeinholding_2023!', // 'carel@123',
        company: 'carel',
        companyLogo: '../assets/images/carel.jpeg',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0NhcmVsJTIwUy5QLkElMjBUZW1wbGF0ZS5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0NhcmVsJTIwUy5QLkElMjBUZW1wbGF0ZV8yLnJ2dA'
    },
    {
        id: '129d36e0-1726-11ee-b32b-fa163e5cdf3e',
        email: 'careluser3@carel.com',
        password: 'Skeinholding_2023!', // 'carel@123',
        company: 'carel',
        companyLogo: '../assets/images/carel.jpeg',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlX2dlbmVyYWxlLnJ2dA'
    },
    {
        id: '8aadf46c-1725-11ee-b32b-fa163e5cdf3e',
        email: 'porte@skeinbim.com',
        password: 'Skeinholding_2023!', // 'skeinbim@123',
        company: 'skeinbim',
        companyLogo: '',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1BvcnRlLnJ2dA'
    },
    {
        id: '90939fa5-1725-11ee-b32b-fa163e5cdf3e',
        email: 't2duser@t2d.com',
        password: 'Skeinholding_2023!', // 't2d@123',
        company: 't2d',
        companyLogo: '../assets/images/t2d.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RFTVBMQVRFJTIwVDJELnJ2dA'
    },
    {
        id: '97970872-1725-11ee-b32b-fa163e5cdf3e',
        email: 'eurocomponentuser@eurocomponent.com',
        password: 'Skeinholding_2023!', // 'eurocomponent@123',
        company: 'eurocomponent',
        companyLogo: '../assets/images/eurocomponent.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0V1cm9jb21wb25lbnRfMi5ydnQ'
    },
    {
        id: '9d644eb8-1725-11ee-b32b-fa163e5cdf3e',
        email: '8000526390@rasmartsurvey.com',
        password: 'Skeinholding_2023!', // 'rasmartsurvey@123',
        company: 'rasmartsurvey',
        companyLogo: '../assets/images/rasmartsurvey.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL2Jhc2lnbGlvX2NlbnRyYWxlJTIwdGVybWljYSUyMEMlMjBDLnJ2dA'
    },
    {
        id: 'a3942d4f-1725-11ee-b32b-fa163e5cdf3e',
        email: '8000526390new@rasmartsurvey.com',
        password: 'Skeinholding_2023!', // 'rasmartsurvey@123',
        company: 'rasmartsurvey',
        companyLogo: '../assets/images/rasmartsurvey.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0Jhc2lsaW9fTE9ENDAwXzMxXzA1XzIyLnJ2dA'
    },
    {
        id: 'a93e55ec-1725-11ee-b32b-fa163e5cdf3e',
        email: 'gpintechuser@gpintech.com',
        password: 'Skeinholding_2023!', // 'gpintech@123',
        company: 'gpintech',
        companyLogo: '../assets/images/gpintech.jpg',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0dQJTIwSW50ZWNoJTIwVGVtcGxhdGUucnZ0'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0dQJTIwSW50ZWNoJTIwVGVtcGxhdGUlMjBuZXcucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0dQJTIwSW50ZWNoJTIwVGVtcGxhdGUlMjBOZXclMjAxLnJ2dA'
    },
    {
        id: 'af401dfa-1725-11ee-b32b-fa163e5cdf3e',
        email: 'greenblokuser@greenblok.com',
        password: 'Skeinholding_2023!', // 'greenblok@123',
        company: 'greenblok',
        companyLogo: '../assets/images/greenblok.jpg',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwZ3JlZW5ibG9jay5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwZ3JlZW5ibG9jayUyMDE2XzFfMjMucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0dSRUVOQkxPS19URU1QTEFURS5ydnQ'
    },
    {
        id: 'b52d6bd8-1725-11ee-b32b-fa163e5cdf3e',
        email: 'disanuser@disan.com',
        password: 'Skeinholding_2023!', // 'disan@123',
        company: 'disan',
        companyLogo: '../assets/images/disan.jfif',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0Rpc2FuX3RlbXBsYXRlLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwRGlzYW4oMSkucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0RJU0FOJTIwVEVNUExBVEUlMjAzLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0Rpc2FuX1RlbXBsYXRlX0NvbXBsZXRvLnJ2dA'
    },
    {
        id: 'ce3eb2ed-1725-11ee-b32b-fa163e5cdf3e',
        email: 'calzolariuser@calzolarisrl.it',
        password: 'Skeinholding_2023!', // 'calzolari@123',
        company: 'calzolari',
        companyLogo: '../assets/images/logo-calzo.svg',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0NhbHpvbGFyaV90ZW1wbGF0ZS5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0NhbHpvbGFyaV90ZW1wbGF0ZS5ydnQ'
    },
    {
        id: 'dffb14e3-1725-11ee-b32b-fa163e5cdf3e',
        email: 'rbmuser@rbm.com',
        password: 'Skeinholding_2023!', // 'rbm@123',
        company: 'rbm',
        companyLogo: '../assets/images/logo-rbm.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RFTVBMQVRFJTIwKDIpLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1JCTV9UZW1wbGF0ZV8xMF8xMF8yMDIzLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RFTVBMQVRFX1IyMDIzXzIzXzExXzIwMjMucnZ0'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RFTVBMQVRFX1IyMDIzLnJ2dA'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1JCTV9URU1QTEFURV9SMjAyMy5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1JCTV9URU1QTEFURV9SMjAyMyUyMCgxKS5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1JCTV9URU1QTEFURV9SJTIwMjAyMy5ydnQ'
    },
    {
        id: 'e7b2a3e5-1725-11ee-b32b-fa163e5cdf3e',
        email: 'arredo@skeinbim.com',
        password: 'Skeinholding_2023!', // 'arredo@123',
        company: 'skeinbim',
        companyLogo: '',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0FSUkVET19TSE9XQ0FTRS5ydnQ'

    },
    {
        id: 'f23c7ad0-1725-11ee-b32b-fa163e5cdf3e',
        email: 'barberiuser@barberi.com',
        password: 'Skeinholding_2023!', // 'Sknh01bim',
        company: 'barberi',
        companyLogo: '../assets/images/barberi.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0JhcmJlcmklMjBUZW1wbGF0ZSUyMC5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0JhcmJlcmklMjBUZW1wbGF0ZS5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0JhcmJlcmklMjBUZW1wbGF0ZV8yMl8wNV8yMDIzLnJ2dA'
    },
    {
        id: 'f7b7f4ac-1725-11ee-b32b-fa163e5cdf3e',
        email: 'maicouser@maico.com',
        password: 'Skeinholding_2023!', // 'maico@123',
        company: 'maico',
        companyLogo: '../assets/images/maico.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlX01haWNvLnJ2dA'
    },
    {
        id: 'f313517e-2f61-11ee-b32b-fa163e5cdf3e',
        email: 'sensitronuser@sensitron.com',
        password: 'Skeinholding_2023!',
        company: 'sensitron',
        companyLogo: '../assets/images/sensitron.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1NFTlNJVFJPTl9URU1QTEFURS5ydnQ'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1NFTlNJVFJPTl9URU1QTEFURV8yOS0wOC5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1NFTlNJVFJPTl9URU1QTEFURV8wN18wOV8yMDIzLnJ2dA'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1NFTlNJVFJPTl9URU1QTEFURV8yMl8wOV8yMDIzLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1NFTlNJVFJPTl9URU1QTEFURV82XzEwXzIwMjMucnZ0'
    },
    {
        id: '6b6590a4-2fc2-11ee-bbb7-020000752840',
        email: 'y.velasco@mygg.it',
        password: 'Skeinholding_2023!',
        company: 'malta',
        companyLogo: '',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0hSSE1BTFRBX0ZFRC1SQlNYX0VfTVlHX1hYWF9DX00zXzAwMDAxLnJ2dA'
    },
    {
        id: '9d796379-4564-11ee-aad6-fa163e5cdf3e',
        email: 'reversouser01@reversoidentity.com',
        password: 'Skeinholding_2023!',
        company: 'reverso',
        companyLogo: '../assets/images/reverso.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0FMRkEucnZ0'
    },
    {
        id: 'e14eb9a3-4564-11ee-aad6-fa163e5cdf3e',
        email: 'reversouser02@reversoidentity.com',
        password: 'Skeinholding_2023!',
        company: 'reverso',
        companyLogo: '../assets/images/reverso.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0Jhc2lnbGlvX2NlbnRyYWxlX3Rlcm1pY2FfMjhfMDZfMjIucnZ0'
    },
    {
        id: 'e14eb9a3-4564-11ee-aad6-fa163e5cde3e',
        email: 'aveuser@ave.com',
        password: 'Skeinholding_2023!',
        company: 'ave',
        companyLogo: '',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0FWRSUyMFByb2plY3QucnZ0'
    },
    {
        id: 'e07cfb37-b10f-11ee-b2dc-020000752840',
        email: 'riellouser@riello.com',
        password: 'Skeinholding_2023!',
        company: 'riello-ups',
        companyLogo: '../assets/images/riello-ups.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1JpZWxsb190ZW1wbGF0ZS5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1JpZWxsb190ZW1wbGF0ZV8wMl8wMl8yMDI0LnJ2dA'
    },
    {
        id: 'c0f0f96f-d138-11ee-b2dc-020000752840',
        email: 'aqasoftuser@aqasoft.com',
        password: 'Skeinholding_2023!',
        company: 'aqasoft',
        companyLogo: '../assets/images/aqasoft.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0FRQVNPRlQlMjBfJTIwVGVtcGxldGUucnZ0',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0FxYXNvZnQlMjBUZW1wbGF0ZSUyME5FVy5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0FxYXNvZnQlMjBUZW1wbGF0ZSUyME5FVyUyMCgxKS5ydnQ'
    },
    {
        id: 'f09532b1-d1fe-11ee-b2dc-020000752840',
        email: 'leucosuser@leucos.com',
        password: 'Skeinholding_2023!',
        company: 'leucos',
        companyLogo: '../assets/images/leucos.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0xldWNvc190ZW1wbGF0ZS5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0xFVUNPUyUyMFRlbXBsZXRlLnJ2dA'
    },
    {
        id: 'f09532ee-d1fe-11ee-b2dc-020000752840',
        email: 'elektroventuser@elektrovent.com',
        password: 'Skeinholding_2023!',
        company: 'elektrovent',
        companyLogo: '../assets/images/elektrovent.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwRWxla3Ryb3Zlbi5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwRWxla3Ryb3ZlbnQucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwRWxla3Ryb3ZlbnQlMjAoMSkucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwRWxla3Ryb3ZlbnQlMjAoMikucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwRWxla3Ryb3ZlbnQlMjAoMykucnZ0'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBfRWxla3Ryb3ZlbnRfMjEtMDYtMjAyNC5ydnQ'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwRWxla3Ryb3ZlbnQlMjAxLnJ2dA'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwRWxla3Ryb3ZlbnQlMjAoNCkucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0VsZWt0cm92ZW50JTIwVGVtcGxhdGVfMTQtMTAtMjQucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0VsZWt0cm92ZW50JTIwVGVtcGxhdGUyNS0xMC0yMDI0LnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0VsZWt0cm92ZW50JTIwVGVtcGxhdGVfMjVfMTBfMjQucnZ0'
    },
    {
        id: '858018c7-d785-11ee-b2dc-020000752840',
        email: 'trezzo@industrialfrigo.com',
        password: 'Skeinholding_2023!',
        company: 'industrial-frigo',
        companyLogo: '../assets/images/industrial-frigo.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JX1RyZXp6b19JbmR1c3RyaWFsJTIwRnJpZ28lMjAxLnJ2dA'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JX1RyZXp6b19JbmR1c3RyaWFsJTIwRnJpZ28lMjAyLnJ2dA'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JX1RyZXp6b19JbmR1c3RyaWFsJTIwRnJpZ28lMjAzX3VwZGF0ZWQucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JX1RyZXp6b19JbmR1c3RyaWFsJTIwRnJpZ28lMjBWMDNfZGV0YWNoZWQucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JX1RyZXp6b19JbmR1c3RyaWFsJTIwRnJpZ28lMjAoMSkucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JX1RyZXp6b19JbmR1c3RyaWFsJTIwRnJpZ28lMjAoNilfZGV0YWNoZWQucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JX1RyZXp6b19JbmR1c3RyaWFsJTIwRnJpZ28lMjAoMikucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JX1RyZXp6b19JbmR1c3RyaWFsJTIwRnJpZ28lMjAoNilfZGV0YWNoZWQlMjAxLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL01JX1RyZXp6b19JbmR1c3RyaWFsJTIwRnJpZ28lMjAoNilfZGV0YWNoZWRfcm9iZXJ0bzU1VFdVLnJ2dA'
    },
    {
        id: '0877cdeb-dc70-11ee-b2dc-020000752840',
        email: 'bim@glassfire.it',
        password: 'Skeinholding_2023!',
        company: 'glassfire',
        companyLogo: '../assets/images/glassfire.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0dsYXNzZmlyZV9UZW1wbGF0ZS5ydnQ'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0dsYXNzZmlyZSUyMFRlbXBsYXRlLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0dsYXNzZmlyZSUyMFRlbXBsYXRlJTIwKDEpLnJ2dA'
    },
    {
        id: 'c22f89e4-06ab-11ef-b2dc-020000752840',
        email: 'lauromarco.pr@gmail.com',
        password: 'Skeinholding_2024!',
        company: 'lauro-marco',
        companyLogo: '../assets/images/lauromarco.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0luZHVzdHJpYWwlMjBTYW1wbGVfZGV0YWNoZWQucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0luZHVzdHJpYWwlMjBTYW1wbGVfZGV0YWNoZWQlMjAxLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0xhdXJvX3NhbXBsZV9kZXRhY2hlZC5ydnQ'
    },
    {
        id: 'c22f89e4-06ab-11ef-b2dc-020001752840',
        email: 'olivebranch@evercoumpounds.com',
        password: 'Skeinholding_2024!',
        company: 'lauro-marco',
        companyLogo: '../assets/images/lauromarco.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1VTQV9UZW5uZXNzZWVfTWVtcGhpcyUyMG5ldy5ydnQ'
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1VTQV9UZW5uZXNzZWVfTWVtcGhpcyUyMDIucnZ0'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1VTQV9UZW5uZXNzZWVfTWVtcGhpcyUyMDMucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1VTQV9UZW5uZXNzZWVfTWVtcGhpcyUyMDQucnZ0'
    },
    {
        id: 'c22f89e4-06ab-11ef-b2dc-020002752840',
        email: 'tecouser@teco.com',
        password: 'Skeinholding_2024!',
        company: 'teco',
        companyLogo: '../assets/images/teco-logo-it.svg',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlY28lMjBUZW1wbGF0ZS5ydnQ'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlY28lMjBUZW1wbGF0ZV8yNF8wOS5ydnQ'
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlY28lMjBUZW1wbGF0ZSUyMCgxKS5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlY28lMjBUZW1wbGF0ZS0zMG9jdDIwMjQucnZ0'
    },
    {
        id: 'c22f89e4-06ab-11ef-b2dc-020003752840',
        email: 'bim@aerauliqa.it',
        password: 'Skeinholding_2024!',
        company: 'aerauliqa',
        companyLogo: '../assets/images/aerauliqa.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RlbXBsYXRlJTIwYWVyYXVsaXFhJTIwMi5ydnQ'
    },
    {
        id: 'c22f89e4-06ab-11ef-b2dc-020004752840',
        email: 'bim@ave.it',
        password: 'Skeinholding_2024!',
        company: 'ave',
        companyLogo: '../assets/images/ave.png',
        //urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0FWRV9UZW1wbGF0ZS5ydnQ'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0FWRV9UZW1wbGF0ZSUyMCgxKS5ydnQ'
    },
    {
        id: 'c12f89e4-06ab-11ef-b2dc-020004751240',
        email: 'slrm@viessmann.com',
        password: 'Skeinholding_2024!',
        company: 'viessmann',
        companyLogo: '../assets/images/viessmann.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1ZJRVNTTUFOTiUyMFRFTVBMQVRFJTIwRklOQUwucnZ0',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1ZJRVNTTUFOTiUyMFRFTVBMQVRFJTIwRklOQUwlMjAzLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1ZJRVNTTUFOTiUyMFRFTVBMQVRFMTctMTAucnZ0'
    },
    {
        id: 'c12f89e4-06ab-11ef-b2dc-020004723170',
        email: 'bim@elesiluce.com',
        password: 'Skeinholding_2024!',
        company: 'elesiluce',
        companyLogo: '../assets/images/elesiluce.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0VMRVNJJTIwVEVNUExBVEUucnZ0'
    },
    {
        id: 'c12f834r-06ab-11ef-b2ed-020004723170',
        email: 'tbkuser@tbk.com',
        password: 'Skeinholding_2024!',
        company: 'tbk',
        companyLogo: '../assets/images/tbk.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RCSyUyMFRlbXBsYXRlLnJ2dA'
    },
    {
        id: 'c12f834r-06ab-11ef-b2ed-020004723170',
        email: 'bimuser@legnobloc.com',
        password: 'Skeinholding_2024!',
        company: 'legnobloc',
        companyLogo: '../assets/images/legnobloc.png',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL1RCSyUyMFRlbXBsYXRlLnJ2dA'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0xlZ25vYmxvYy0zMG9jdDIwMjQucnZ0'
    },
    {
        id: 'c12f834r-06ab-11ef-a2ed-024504723170',
        email: 'bimuser@cias.com',
        password: 'Skeinholding_2024!',
        company: 'cias',
        companyLogo: '../assets/images/cias.png',
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0NJQVMlMjBURU1QTEFURS5ydnQ'
    },
    {
        id: 'c12f834r-06ab-11ef-a2ed-024504723170',
        email: 'bimuser@eplanzini.it',
        password: 'Skeinholding_2024!',
        company: 'ep-lanzini',
        companyLogo: '../assets/images/lanzini-logo.svg',
        // urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0VQX0xBTlpJTklfVEVNUExBVEUucnZ0'
        urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MDhhYWYzYjQtZTkyYi0xMWViLWE4MzgtMDIwMDAwNzUyODQwL0VQX0xBTlpJTklfVEVNUExBVEVfTmV3LnJ2dA'
    }
];
